import { ref } from 'vue';
import { checkLogin } from '@/plugins/auth';

// 存储全局登录状态
export const useAuth = () => {
    const isLoggedIn = ref(false);
    const username = ref('');

    // 初始化登录状态
    const initAuth = async () => {
        try {
            const user = await checkLogin();
            isLoggedIn.value = !!user;
            username.value = user || '';
        } catch (error) {
            isLoggedIn.value = false;
            username.value = '';
            console.error('登录状态初始化失败', error);
        }
    };

    return { isLoggedIn, username, initAuth };
};