import axios from 'axios'
import {getCookieByKey} from "@/plugins/cookie";
import {AuthList,checkLoginLocal} from "@/plugins/auth";
import {API_OAUTH_CHECK_LOGIN} from "@/api/api";

axios.defaults.baseURL = '/'

// 如果请求话费了超过 `timeout` 的时间，请求将被中断s
axios.defaults.timeout = 10800000;
// 表示跨域请求时是否需要使用凭证
axios.defaults.withCredentials = true;
axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded; charset=UTF-8;';
// 允许跨域
axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*';

// request拦截
axios.interceptors.request.use(
    config => {
        const token = getCookieByKey('token');
        // 1. 检查登录的URL，必须携带token
        if (config.url.startsWith(API_OAUTH_CHECK_LOGIN)) {
            config.headers.Authorization = `Bearer ${token}`;
            return config
        }
        // 2. 强鉴权URL，仅需要做弱检查，强检查留给路由层做
        if (AuthList.some(url => config.url.startsWith(url))) {
            if(!checkLoginLocal()) {
                // 未登录，由路由层拦截，无需处理
                return config
            }else {
                // 必然已登录，可能过期
                // 添加token
                config.headers.Authorization = `Bearer ${token}`;
                return config
            }
        }
        // 3. 其余URL，如果已经登录，才需要token
        if(!checkLoginLocal()) {
            return config
        }else {
            config.headers.Authorization = `Bearer ${token}`;
            return config
        }
    },
    error => {
        console.log("error");
        return Promise.reject(error);
    }
);

axios.interceptors.response.use(
    response => {
        // 200，正常放行
        return response;
    },
    error => {
        if (error.response) {
            const { status, config } = error.response;
            // 只有非强鉴权接口需要检查
            if (status === 401 && !AuthList.some(url => config.url?.startsWith(url))) {
                // 没有token，表示未登录，不管
                if (!checkLoginLocal()) {
                    return Promise.reject(error);
                }
                // 有token，表示过期，重定向到登录
                window.location.href = '/oauth/login';
            }
        }
        return Promise.reject(error); // 将错误抛出以便调用方处理
    }
)

export default axios
