"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getCookieByKey = void 0;
/**
 * 获取Cookie的value
 * @param {string} key - Cookie的键
 * @returns {string|null} - Cookie的值，可能为null
 */
var getCookieByKey = function (key) {
    var cookies = document.cookie.split('; ');
    for (var _i = 0, cookies_1 = cookies; _i < cookies_1.length; _i++) {
        var cookie = cookies_1[_i];
        var _a = cookie.split('='), cookieKey = _a[0], cookieValue = _a[1];
        if (cookieKey === key) {
            return decodeURIComponent(cookieValue); // Return decoded value
        }
    }
    return null;
};
exports.getCookieByKey = getCookieByKey;
