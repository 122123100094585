"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.shortenString = void 0;
function shortenString(str) {
    if (str.length <= 6) {
        return str; // 如果字符串长度小于或等于6，直接返回
    }
    return str.slice(0, 4) + '...' + str.slice(-4); // 前3个字符 + ... + 最后3个字符
}
exports.shortenString = shortenString;
