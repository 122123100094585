// src/directives/auth.ts
import { checkLogin } from '@/plugins/auth';

export const useAuthDirective = () => {
    return {
        mounted(el, binding) {
            el.addEventListener('click', async (event) => {
                try {
                    const username = await checkLogin();
                    if (!username) {
                        alert('请先登录');
                        window.location.href = '/oauth/login'; // 跳转到登录页面
                        event.preventDefault(); // 阻止未登录时的默认操作
                    } else if (binding.value) {
                        binding.value(); // 执行绑定的事件
                    }
                } catch (error) {
                    console.error('权限验证失败', error);
                    alert('发生错误，请稍后再试');
                }
            });
        },
        beforeUnmount(el) {
            el.removeEventListener('click'); // 清除事件绑定，防止内存泄漏
        },
    };
};